<template>
  <div class="item">
    <el-row>
      <TitleHeader msg="我的账户"> </TitleHeader>
      <el-col class="myAcount">
        <el-col :span="12" class="list">
          <el-col class="acount"
            ><span>账户名称：</span
            ><span class="name">{{ info.enterpriseName }}</span></el-col
          >
          <el-col
            ><span>会员等级：</span
            ><span class="levelBg text-center">{{
              info.memberLevelName
            }}</span></el-col
          >
          <el-col
            ><span>我的客服：</span><span class="service">{{ info.customName }}</span
            ><span class="person">{{ info.customPhone }}</span></el-col
          >
        </el-col>
        <el-col :span="4" class="balance text-center">
          <span class="balanceNum">{{ info.balance }}</span>
          <h3>预存款 (元)</h3>
          <el-col class="action">
            <span @click="jumpRecharge">充值</span>
            <span>|</span>
            <span @click="jumpTransferAccount">转账</span>
          </el-col>
        </el-col>
        <!-- 药豆 start-->
        <el-col :span="4" class="balance text-center">
          <span class="balanceNum">{{
            PointsNum ? PointsNum : 0
          }}</span>
          <h3>药豆</h3>
          <el-col class="action">
            <span @click="jumpPointsList">查看</span>
            <span>|</span>
            <span @click="jumpPointsRules">规则</span>
          </el-col>
        </el-col>
<!-- 药豆 end-->
        <el-col :span="4" class="text-center balance">
          <span class="balanceNum">{{
            couponNum ? couponNum : 0
          }}</span>
          <h3>优惠券(张)</h3>
          <el-col class="action">
            <span @click="jumpCoupon">查看</span><span>|</span><span @click="jumpGetCoupon">领取</span>
          </el-col>
        </el-col>
      </el-col>
    </el-row>
    <el-row class="myOrder">
      <TitleHeader msg="我的订单"> </TitleHeader>
      <el-row class="orderStatus">
        <div v-for="(item, index) in imgList" :key="index">
          <router-link
            tag="div"
            :to="{ name: `${item.link}`, params: { type: `${item.type}` } }"
          >
            <el-badge :value="item.num" class="badge text-center">
              <img v-if="index===0" src="@/assets/member_icon1.png" alt="0"/>
              <img v-if="index===1" src="@/assets/member_icon2.png" alt="1"/>
              <img v-if="index===2" src="@/assets/member_icon3.png" alt="2"/>
              <img v-if="index===3" src="@/assets/member_icon4.png" alt="3"/>
              <img v-if="index===4" src="@/assets/member_icon5.png" alt="4"/>
              <div class="text-center">{{ item.name }}</div>
            </el-badge>
          </router-link>
        </div>
      </el-row>
      <el-row class="logistics">
        <h2><img src="~assets/logistics.png" alt="" /><span>最新物流</span></h2>
        <el-col class="wraper">
          <el-col>
            <el-col :span="18">{{ info.enterpriseName }}</el-col>
          </el-col>
          <!-- 物流信息 -->
          <el-col :span="24" v-if="obj.routesArray">
            <el-col v-for="(item, index) in obj.routesArray.slice(-1)" :key="index">
              <el-col :span="24">
                <el-col :span="8" v-if="item.mailNo"
                  >运单号：{{ item.mailNo }}</el-col
                >
                <el-col class="logisticState" :span="8" v-if="item.sendState"
                  >{{ item.sendState }}</el-col
                >
              </el-col>
              <el-col :span="24">
                <el-col :span="12" v-if="item.acceptAddress">
                  收货地址：{{ item.acceptAddress }}
                </el-col>
              </el-col>
              <el-col :span="24" class="Newdetail">
                <el-col :span="8" v-if="item.acceptTime">
                  {{ item.acceptTime }}
                </el-col>
                <el-col  class="info" :span="14" v-if="item.remark">{{ item.remark }}</el-col>
              </el-col>
            </el-col>
          </el-col>
        </el-col>
      </el-row>
    </el-row>
    
    <el-dialog
        title="药豆规则"
        :visible.sync="dialogVisible"
        width="60%"
        center
        >
        <IntegralRule></IntegralRule>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="dialogVisible = false">关  闭</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { MemberInfo, Getnewest } from "api/member.js";
import { PostorderCount } from "api/order.js";
import {CouponNum,userPointsNum} from "api/member.js";
// import Qs from 'qs'
const TitleHeader = () => import("components/common/TitleHeader.vue");
const IntegralRule = () => import("views/IntegralRule.vue")
export default {
  name: "MyAccount",
  data() {
    return {
       dialogVisible:false,
      PointsNum:0,
      couponNum:0,
      info: {},
      imgList: [
        {
          name: "待付款",
          num: 0,
          type: 1,
          link: "MyOrder",
        },
        {
          name: "待配送",
          num:0,
          type: 2,
          link: "MyOrder",
        },
        {
          name: "待收货",
          num:0,
          type: 3,
          link: "MyOrder",
        },
        {
          name: "退款/售后",
          num: 0,
          type: 4,
          link: "AfterRefund",
        },
        {
          name: "全部订单",
          num:0,
          type: 0,
          link: "MyOrder",
        },
      ],
      obj: {
       
      },
    };
  },
  methods: {
    getMemberInfo: function() {
      // 获取药豆数量
      userPointsNum().then(res=>{
        this.PointsNum=res.data.data.points
      })
      // 获取优惠券数量
      CouponNum().then(res=>{
        this.couponNum=res.data.data
      })
      // 会员信息
      MemberInfo().then((res) => {
        // const productData = Qs.parse(Qs.stringify(res.data)).data[0];
        this.info = res.data.data;
      });
      // 最新物流
      Getnewest().then((res) => {
        let code=res.data.code
        if(res.data.msg!=="暂无承载数据"){
           if(code!==400){
             if(res.data.data.logiInformation!=''){
                 const routesArray = JSON.parse(res.data.data.logiInformation);
                 this.$set(this.obj, "routesArray", routesArray);
             }
           }
         
        }
        
      });
      // 订单数量
      PostorderCount().then((res) => {
        this.imgList[0].num=res.data.data.toBePaid      //待付款
        this.imgList[1].num=res.data.data.paided        //待配送
        this.imgList[2].num=res.data.data.toBeDelivery  //待收货
        this.imgList[3].num=res.data.data.refundCount   //退款/售后
        this.imgList[4].num=res.data.data.orderTotal    //全部订单
      });
    },
    jumpMyOrder() {
      this.$router.push({ name: "MyOrder" });
    },
    jumpBalance() {
      this.$router.push({ name: "Balance" });
    },
    
//跳转药豆明细列表
jumpPointsList(){
  this.$router.push({ name: "Points" });
},

//跳转药豆规则
jumpPointsRules(){
  this.dialogVisible = true
},
    jumpRecharge() {
      // this.$message({
      //         message:'该功能，暂未开启！',
      //         type: "warning",
      //       });
      this.$router.push({ name: "Recharge" });
    },
    jumpTransferAccount() {
      this.$router.push({ name: "TransferAccount" });
    },
    jumpCoupon() {
      this.$router.push({ name: "Coupon" });
    },
    jumpGetCoupon(){
       this.$router.push({ name: "GetCoupon" });
    }
  },
  created() {
    this.getMemberInfo();
  },
  computed: {
    ...mapState({
      userName: (state) => state.userName,
    }),
  },
  components: {
    TitleHeader,
    IntegralRule
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";

.myAcount {
  .fonts();
  color: #666;
  padding: 20px 14px;
  .list {
    padding-right: 20px;
    padding-bottom: 20px;
    border-right: 1px solid#F0F0F0;
    .levelBg {
      display: inline-block;
      width: 70px;
      height: 18px;
      background: url("~assets/levelBg.png") no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
      font-size: 12px;
      line-height: 18px;
      padding: 4px;
      padding-left: 15px;
    }
    .el-col {
      padding-top: 15px;
    }
    .acount {
      span {
        vertical-align: top;
      }

      .name {
        display: inline-block;
        width: 360px;
        color: #333;
      }
    }
    .service {
      color: #333;
    }
    .person {
      padding-left: 10px;
      color: #333;
    }
    .el-col:first-child {
      padding-top: 0px;
    }
  }
  .balance {
    padding-top: 10px;
    .balanceNum {
      color: #333;
      font-size: 24px;
      line-height: 33px;
      font-weight: bold;
    }
    h3 {
      padding-top: 16px;
      font-size: 12px;
      color: #666;
      line-height: 17px;
    }
    .action {
      padding-top: 16px;
      span {
        color: #597ef7;
        padding-left: 10px;
         cursor: pointer;
            &:hover{
                color: @theme;
            }
      }
      span:nth-child(2) {
        color: #ccc;
         cursor: pointer;
            &:hover{
                color: @theme;
            }
      }
    }
  }
}
.myOrder {
  margin-top: 20px;
  .orderStatus {
    padding: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      display: inline-block;
      width:42px;
      height:42px;
    }
    .badge {
      margin-top: 20px;
      margin-right: 40px;
       cursor: pointer;
            &:hover{
                color: @theme;
            }
    }
    .el-badge__content.is-fixed {
      top: 12px;
      right: 14px;
    }
  }
}

.logistics {
  color: #333;
  font-size: 14px;
  line-height: 20px;
  background: #f6f6f6;
  padding: 20px;
  border-radius: 5px;
  .logisticState {
    color: @theme;
  }
  h2 {
    padding-top: 10px;
    font-size: 16px;
    padding-bottom: 10px;
    img {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: top;
    }
    span {
      padding-left: 10px;
    }
  }
  & > div {
    padding-top: 10px;
  }
  .info {
    display: flex;
    align-items: center;
  }
  .wraper {
    padding-top: 12px;
    padding-left: 30px;
    text-indent: 20px;
    & > div {
      padding-top: 10px;
    }
    .Newdetail {
      padding:5px 0 10px 0;
      margin-bottom: 10px;
      // border-bottom:1px solid #ccc ;
    }
  }
}
</style>
